<template>
<scale-box>
	<div class="guide">
        <div class="title">招商图谱</div>
        <img class="back" :src="require('/static/data.png')" alt="">
		<div class="guide-box">
            <div class="box-middle">
                <div class="middle-top">
                    <div class="top-left">
                        <div class="left-title">
                            <span class="span1">商标</span>
                        </div>
                        <div class="left-list">
                            <div>
                                <div class="list-title">
                                    <div>序号</div>
                                    <div>商品名称</div>
                                    <div>所属类目</div>
                                    <div>总申请次数</div>
                                    <div>申请次数</div>
                                    <div>通过量</div>
                                </div>
                                <div style="clear:both;"></div>
                                <div v-for="(v,k) in tableData1" class="list-for" :key="k">
                                    <div>{{k+1}}</div>
                                    <div class="list-input">
                                         <el-select v-model="v.value" @change="get(k,v.value)" placeholder="请选择" style="width:210px;height:48px;">
                                            <el-option v-for="(item,index) in v.details"
                                                    :key="item.detailName"
                                                    :label="item.detailName"
                                                    :value="index">{{item.detailName}}</el-option>
                                         </el-select>
                                    </div>
                                    <div>{{v.intcls}}类 {{v.categoryName}}</div>
                                    <div>{{v.applyCnt}}</div>
                                    <div v-if="v.value == v.details[0].detailName">{{v.details[0].detailSum}}</div>
                                    <div v-else>{{v.details[v.value].detailSum}}</div>
                                     <div style="color:#F5E055;" v-if="v.value == v.details[0].detailName">{{v.details[0].validSum}}</div>
                                    <div style="color:#F5E055;" v-else>{{v.details[v.value].validSum}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-left right">
                        <div class="left-title">
                            <span class="span1" style="color:#18FFD8">专利</span>
                        </div>
                        <div class="left-list">
                            <el-table :row-class-name="tableRowClassName" :data="tableData3" @row-click="handleTableRow" :header-cell-style="{color:'#fff'}" style="width: 810px;overflow-y: auto;margin:0 auto;" :height="height + 'px'">
                                <el-table-column type="index" label="序号" width="60" align="center" />
                                <el-table-column prop="fenleiText" label="专利名称" align="center" />
                                <el-table-column prop="fenlei1Text" width="180" label="所属行业" align="center" />
                                 <el-table-column prop="count" width="120" align="center"  label="申请量">	
                                    <template v-slot="scope">
                                        <span style="color:#16FF83;">{{scope.row.count}}</span>
                                    </template>
                                </el-table-column>
		                    </el-table>
                        </div>
                    </div>
                </div>
                <div class="middle-bot">
                    <div class="bot-left">
                        <div class="left-title">商标布局</div>
                        <div class="bot-list">
                              <div class="bot-title">
                                <div>序号</div>
                                <div>区域内</div>
                                <div>省内区域外</div>
                                <div>省外</div>
                              </div>
                              <div class="list-detail" >
                                <div v-for="(v,k) in tableData2" :key="k" class="detail1">
                                    <div>{{k+1}}</div>
                                    <div class="detail-span">{{v.tmapplicant}}（<span>{{v.detailName}}</span>类商标申请<span>{{v.detailSum}}</span>次 通过<span>{{v.validSum}}</span>次）</div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>  
                        </div>
                        <div>
                             <span class="span2">进入产业导航</span>
                            <el-select v-model="value1" placeholder="请选择" style="width:160px;height:48px;margin-left:40px">
                            <el-option v-for="item in optionm1"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.name"></el-option>
                        
                           </el-select>
                        </div>
                    </div>
                    <div class="bot-left right2">
                        <div class="left-title" style="color:#18FFD8">专利布局</div>
                        <div class="bot-list">
                              <div class="bot-title act">
                                <div>序号</div>
                                <div>区域内</div>
                                <div>省内区域外</div>
                                <div>省外</div>
                              </div>
                              <div class="list-patent">
                                <div>
                                    <div v-for="(v,k) in tableData4" :key="k">
                                        <div>{{k+1}}</div>
                                        <div class="detail-span">{{v.applicant}}申请大类<span>{{v.fenlei1}}</span>小类<span>{{v.fenlei}}</span>专利<span>{{v.count}}</span>次</div>
                                        
                                    </div>
                                </div>
                                <div>
                                    <div v-for="(item,m) in tableData5" :key="m+'a'">
                                        <div class="detail-span">{{item.applicant}}申请大类<span>{{item.fenlei1}}</span>小类<span>{{item.fenlei}}</span>专利<span>{{item.count}}</span>次</div>
                                    </div>
                                </div>
                                <div>
                                    <div v-for="(item,m) in tableData6" :key="m+'n'">
                                        <div class="detail-span">{{item.applicant}}申请大类<span>{{item.fenlei1}}</span>小类<span>{{item.fenlei}}</span>专利<span>{{item.count}}</span>次</div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div>
                            <span class="span2">进入产业导航</span>
                            <el-select v-model="value1" placeholder="请选择" style="width:160px;height:48px;margin-left:40px">
                            <el-option v-for="item in optionm1"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.name"></el-option>
                        
                           </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
    </scale-box>
</template>
<script>
import scaleBox from "../../components/data/sclae-box";
export default {
  name: '',
  data(){
    return {
	  value1:'',
      height:850,
      optionm1:[],
      tableData1:[],
      tableData2:[],
      tableData3:[],
      tableData4:[],
      tableData5:[],
      tableData6:[],
    }
  },
 
  components: {
   scaleBox
  },
  watch:{
    
    
  },
  methods: {
	 getData(){
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/getTupuTrademark',
					{
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					}
            ).then((res) => {
				this.tableData1.push(...res.data.data)
                var _this = this;
                this.tableData1.map(function (item) {
                _this.$set(item, 'value', item.details[0].detailName);
              });
              this.tableData2 = this.tableData1[0].details[0].applicantVos;
              } 
			)		
	 },
     get(val1,val2){
        this.tableData2 = this.tableData1[val1].details[val2].applicantVos;
     },
     getData2(){
				this.$http.post(this.GLOBAL.new_url + '/patent/service/data/getTupuPatent',
					{
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					}
            ).then((res) => {
                console.log(res)
				this.tableData3.push(...res.data.data)
               this.tableData4 = this.tableData3[0].applicantVoList;
               this.tableData5 = this.tableData3[0].outCityApplicantVoList;
               this.tableData6 = this.tableData3[0].outProvinceApplicantVoList;
              } 
			)		
	 },
    
     tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
      handleTableRow(row,column){
		// console.log(row)
        this.tableData4 = this.tableData3[row.index].applicantVoList;
        this.tableData5 = this.tableData3[row.index].outCityApplicantVoList;
        this.tableData6 = this.tableData3[row.index].outProvinceApplicantVoList;
	 },
	useHeight(){
				return new Promise((res) => {
					this.$nextTick(() => {
						let heightCount = 0
						let mainEl = document.querySelector('.table-container' + this.index)
						heightCount = mainEl?.clientHeight
						heightCount = heightCount - 36 - 45
						this.height= heightCount
		                res(heightCount)
					})
				})				
			},
    
  },
   mounted() {
    this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData();
      this.getData2();
      this.useHeight()
    },
     beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#0e2140;')
        },
}
</script>

<style scoped>
	.guide-box{
        width: 1920px;
        padding: 40px 0;
        height: 2150px;
        /* background-size: cover; */
          /* background: url('../../../static/data.png') 100% no-repeat; */
        margin: 60px auto 0;
        position: relative;
    }
    .back{
        position: absolute;
        width: 1920px;
        height: 980px;
        top:0;
        margin: 0 auto;
    }
    .title{
        width: 100%;
         position: absolute;
         top:0;
         height: 80px;
         line-height: 80px;
         font-size: 32px;
         color: #fff;;
         background: #001B62;
         text-align: center;
         z-index: 10;
    }
    .box-middle{
        width: 1800px;
        margin: 0 auto;
    }
    .middle-top{
        height: 995px;
    }
    .middle-top>div{
        float: left;
    }
    .top-left{
        width: 850px;
         /* border:1px solid red; */
        text-align: center;
        margin-right: 100px;
    }
    .right,.right2{
        margin-right: 0!important;
    }
    .left-title{
      
    }
    .left-list{
        width: 850px;
        height: 920px;
        padding-top: 30px;
        background: url('../../../static/images/guide-list1.png') 100% no-repeat;
    }
    .span1{
        font-size: 28px;
        color: #18F8FF;
        margin-right: 40px;
    }
    .span2{
        display: inline-block;
        width: 260px;
        height: 85px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 80px;
        background: url('../../../static/images/guide-title.png') 100% no-repeat;
    }
    :deep(.el-input__inner){
	border:1px solid #18F8FF!important;
	color: #fff !important;
	/* padding-left: 0!important; */
	background: #333333!important;
}
.tab-searh :deep(.el-input__inner){
    padding-left: 14px!important;
    color: #18F8FF!important;
}
.el-select-dropdown__item {
  font-size: 10px;
  color: #666666;
  font-weight: 500;
}
:deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #fff;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    font-size: 18px;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 65px;
  line-height: 65px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
    font-size: 18px;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 72px;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 .middle-bot{
    height: 1125px;
 }
 .middle-bot>div{
    float: left;
 }
 .bot-left{
    width: 850px;
    text-align: center;
    margin-right: 100px;
 }
 .left-title{
   font-size: 28px;
   color: #18F8FF;
 }
 .bot-list{
     width: 850px;
     padding: 30px 0 0 0;
     height: 1025px;
     background: url('../../../static/images/guide-list2.png') 100% no-repeat;
 }
 .bot-title,.list-title{
    width: 810px;
    margin: 0 auto;
    height: 72px;
 }
 .bot-title>div{
    float: left;
    line-height: 72px;
    text-align: center;
    height: 72px;
    color: #fff;
    font-size: 20px;
    width: 202.5px;
    border-bottom: 1px solid #69B1FF;
 }
 .bot-title>div:nth-of-type(1){
    width: 200px;
 }
 .list-detail{
    height: 925px;
    width: 810px;
    margin: 0 auto;
    overflow-y: auto;
 }
 .list-detail>div{
    height: 160px;
    font-size: 18px;
    padding-top: 16px;
    width: 810px;
     margin: 0 auto;
    border-bottom: 1px solid #69B1FF;
 }
 .list-detail>div>div{
    float: left;
    width: 202.5px;
    font-size: 18px;
    color: #FFFFFF;
 }
 .list-patent{
    height: 925px;
    width: 810px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
 }
 .list-patent>div{
    float: left;
    
 }
 .list-patent>div:nth-of-type(1)>div{
    border-bottom: 1px solid #69B1FF;
    width: 420px;
    height: 160px;
     padding-top: 16px;
    font-size: 18px;
    color: #FFFFFF;
 }
 .list-patent>div:nth-of-type(1)>div>div{
    float: left;
    width: 202.5px;
 }
  .list-patent>div:nth-of-type(2)>div{
    border-bottom: 1px solid #69B1FF;
    width: 180px;
    height: 160px;
     padding: 16px 10px 0;
    font-size: 18px;
    color: #FFFFFF;
 }
 .list-patent>div:nth-of-type(3)>div{
    border-bottom: 1px solid #69B1FF;
    width: 157px;
    height: 160px;
      padding: 16px 10px 0;
    font-size: 18px;
    color: #FFFFFF;
 }
.act>div{
    color:#fff;
}
.detail1:hover {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 .list-title>div{
    float: left;
    border-bottom: 1px solid #69B1FF;
    width: 135px;
    font-size: 18px;
    line-height: 72px;
    text-align: center;
    color: #fff;
 }
 .list-title>div:nth-of-type(1){
    width: 50px;
 }
  .list-title>div:nth-of-type(2){
    width: 220px;
 }
 .list-for{
    width: 810px;
    margin: 0 auto;
    height: 81px;
     border-bottom: 1px solid #69B1FF;
 }
 .list-for>div{
     color: #fff;
    float: left;
    font-size: 18px;
     width: 135px;
     line-height: 81px;
     text-align: center;
    
 }
 .list-for>div:nth-of-type(1){
    width: 50px;
 }
  .list-for>div:nth-of-type(2){
    width: 220px;
 }
 .list-for:hover{
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 .list-input :deep(.el-input__inner){
    border:1px solid #69B1FF!important;
	color: #fff !important;
    background: transparent!important;
    font-size: 18px;
 }
 .detail-span>span{
    color: #18F8FF;
 }
</style>


